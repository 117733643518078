import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container mx-auto py-4" }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "space-y-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "font-semibold" }
const _hoisted_7 = { class: "text-gray-500 text-sm" }

import { getAccessToken, setTenantId } from "@/utils/storage";
import { useRoute, useRouter } from "vue-router";
import AccountModule from "@/store/modules/Account";
import { TenantAPI } from "@/services/tenant";
import { ITenantProfileDTO } from "@/models/Tenant";
import { onMounted, ref } from "vue";

const imageUrl = "https://via.placeholder.com/50";
const redirectCreatePath = "/tenant/create";

export default /*@__PURE__*/_defineComponent({
  __name: 'TenantUserSelect.Form',
  setup(__props) {

const router = useRouter();
const route = useRoute();

const tenants = ref({} as ITenantProfileDTO[]);
const selectedTenant = ref({} as ITenantProfileDTO);

const selectTenant = async (tenant: ITenantProfileDTO) => {
  selectedTenant.value = tenant;
  setTenantId(selectedTenant.value.Id ?? "");
  await AccountModule.loginIdentity(selectedTenant.value.Id);
  router.push("/");
};

const fetchTenants = async () => {
  const accessToken = getAccessToken();
  const serviceTenantAPI = new TenantAPI(accessToken ?? "");
  const { data, error } = await serviceTenantAPI.getAllTenants();
  tenants.value = data as ITenantProfileDTO[];
  console.log(tenants.value);
};

onMounted(async () => {
  await fetchTenants();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "text-2xl font-bold mb-4" }, "Tenant List", -1)),
      _createElementVNode("button", {
        class: "btn btn--green-primary py-2",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push(`${redirectCreatePath}`)))
      }, " Create New Tenant ")
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tenants.value, (tenant) => {
        return (_openBlock(), _createElementBlock("li", {
          key: tenant.Name,
          onClick: ($event: any) => (selectTenant(tenant)),
          class: _normalizeClass({
          'bg-blue-100': selectedTenant.value?.Name === tenant.Name,
          'cursor-pointer': true,
          'p-4': true,
          'rounded-lg': true,
          'shadow-md': true,
          flex: true,
          'justify-between': true,
          'items-center': true,
        })
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: imageUrl,
              alt: "User Avatar",
              class: "w-12 h-12 rounded-full mr-4"
            }),
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(tenant.Name), 1),
              _createElementVNode("p", _hoisted_7, " Latitude: " + _toDisplayString(tenant.lat) + " - Longitude: " + _toDisplayString(tenant.lng), 1)
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("svg", {
            class: "w-6 h-6 text-gray-400",
            fill: "none",
            stroke: "currentColor",
            viewBox: "0 0 24 24",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d: "M9 5l7 7-7 7"
            })
          ], -1))
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}
}

})