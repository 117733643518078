import { defineComponent as _defineComponent } from 'vue'
import { useRoute, useRouter } from "vue-router";
import SVGWorldMap from "@/assets/images/bg/world.svg";
import TenantUserSelectForm from "@ems/containers/Tenant/TheForm/TenantUserSelect.Form.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectTenant',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();

const __returned__ = { router, route, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get SVGWorldMap() { return SVGWorldMap }, TenantUserSelectForm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})